* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-sizing: border-box;
}

::after,
::before {
    box-sizing: border-box;
}

body {
    background: #f3f5f7;
    color: var(--black);
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.main-container {
    max-width: 1600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

aside {
    background: #ffffff;
    padding: 8px 34px;
}

@media screen and (max-width: 1024px) {
    aside {
        max-height: 600px;
        padding: 16px;
    }
}

@media screen and (max-width: 280px) {
    aside {
        padding: 12px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1.1;
}

h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
    margin-bottom: 18px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

p {
    margin: 0 0 10px;
}

.checkbox,
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 20px;
    min-height: 20px;
}

.checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
}

.checkbox + .checkbox,
.radio + .radio {
    margin-top: -5px;
}

.checkbox label,
.radio label {
    min-height: 20px;
    cursor: pointer;
}
.modal-dialog {
    height: 100%;
    margin: 0 auto !important;
    width: 100% !important;
}
.color-white-green {
    color: var(--white-light) !important;
    transition: all 0.3s;
    &:hover,
    &.active {
        color: var(--corp-green) !important;
    }
}
.delete-button {
    transition: all 0.3s;
    color: var(--error-60) !important;
    .agp-button__content {
        color: var(--error-60) !important;
    }

    &:hover {
        opacity: 0.8;
    }
}
.row-center {
    display: flex;
    align-items: center;
}
.text-right {
    display: flex;
    justify-content: flex-end;
}
#profile {
    overflow-x: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .header {
        position: sticky;
        top: 0;
        left: 0;
        background: var(--black-20);
        display: flex;
        align-items: center;
        padding: 5px;
        z-index: 99;
        flex-direction: column;
        section {
            width: 100%;
            display: flex;
            align-items: center;
            @media screen and (max-width: 576px) {
                justify-content: space-between;
            }
        }
        .logo {
            width: 45px;
            img {
                width: 100%;
            }
        }
        nav {
            flex: 1;
            padding: 0px 16px;
            a {
                font-size: 16pt;
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }
        .logout {
            .agp-button__content {
                color: var(--corp-green);
            }
        }
        @media screen and (max-width: 576px) {
            justify-content: space-between;
            position: sticky;
            top: 0;
        }
    }
}
.w-60 {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
}
.w-30 {
    width: 30px;
    min-width: 30px;
    max-width: 30px;
}
.revers-arrow-right {
    svg {
        transform: rotate(180deg);
    }
}
.for-outside-icon {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
}
.weight-bold{
    font-weight: bold;
}
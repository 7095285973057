.agp-modal-notification__header__icon {
  margin-bottom: 16px;
}

.agp-modal-notification__header {
  margin-bottom: 16px;
}

.agp-modal-notification__body {
  font-size: 16px;
  font-family: Golos, sans-serif;
  line-height: 24px;
  margin-bottom: 24px;
}

.agp-modal-notification__description {
  font-size: 14px;
  color: var(--gray-60);
  margin-bottom: 24px;
}

.error-grey path:first-child {
  fill: #EEEFF2;
}

.error-grey path:nth-child(2) {
  fill: #DFE1E6;
}

.error-grey path:nth-child(4) {
  fill: #919EAB;
}

.agp-modal-notification__title {
  color: var(--gray-90);
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  margin: 0;
}

.agp-modal-notification__description-text {
  margin: 14px 0 0 0;
  color: var(--gray-90);
  font-size: 16px;
  line-height: 24px;
}


.agp-modal-notification__symbol {
  color: var(--gray-90);
}

@media (max-width: 320px) {

  .agp-modal-notification__header__title h2 {
    font-size: 21px;
    line-height: 24px;
  }

}
.agp-modalDialog {
  box-sizing: border-box;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0 20px 32px rgba(0, 20, 67, 0.12);
  border-radius: 8px;
  position: relative;
}

.agp-modalDialog__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: var(--gray-10);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition:  background-color 0.3s ease-in-out;
  box-shadow:unset;
}

.agp-modalDialog__close:hover {
  background-color: var(--gray-20);
}

.agp-modalDialog__close:focus {
  box-shadow: 0px 0px 0px 4px var(--focus);
}

.agp-modalDialog__close:active {
  background-color: var(--gray-30);
  box-shadow:unset;
}

.agp-modalDialog_form {
  margin-top: 112px;
}
.agp-modalDialog_info {
  margin-top: 144px;
}

.agp-modalDialog__paddingTop_40 {
  padding-top: 40px;
}

.agp-modalDialog__paddingTop_32 {
  padding-top: 32px;
}

.agp-modalDialog__paddingTop_24 {
  padding-top: 24px;
}

.modal-dialog-centered {
  min-height: auto !important; //переопределение с бутстрап
}

@media (max-width: 768px) {
  .agp-modalDialog {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .agp-modalDialog_form {
    margin: 0;
    min-width: 100%;
  }

  .agp-modalDialog_form .modal-content {
    margin: 0;
    border-radius: 0;
    min-height: 100vh;
  }

  .agp-modalDialog_info .modal-content {
    margin: 0 16px;
  }

  .agp-modalDialog_form .agp-modalForm {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 16px!important;
  }

  .agp-modalDialog_form .agp-modalForm__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
  }

  .agp-modalDialog_form form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }


  .agp-filterModal__content .agp-buttonsBlock__innerWrapper {
    padding: 0 16px;
  }

  .agp-filterModal__content .agp-datepicker__wrapper {
    margin-top: 18px;
  }

  .agp-filterModal__content .agp-radiobutton__label,
  .agp-filterModal__content .agp-checkbox__label,
  .agp-filterModal__inputInfo {
    font-size: 14px;
  }

  .agp-modalDialog__close {
    right: 12px;
    top: 12px;
  }
}

@media (max-width: 719px) {
  .agp-modalDialog_info {
    margin-top: 64px;
  }
}

@media (max-width: 567px) {
  .agp-modalDialog_info {
    margin-top: 32px;
  }
}

.agp-button {
  padding: 0 32px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  border: none;
}

.agp-button_ghost {
  padding: 0 !important;
}

.agp-button_full-width {
  width: 100%;
}

.agp-button > .agp-button__content {
  font-family: Golos, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.agp-button:focus,
.agp-button:active {
  outline: none;
}

.agp-button_primary {
  // TODO поправить размеры после подключения шрифта + анимация при нажатии + внешние границы
  background-color: var(--corp-green);
  box-shadow: 0 0 0 4px transparent;
  -webkit-box-shadow: 0 0 0 4px transparent;
  -moz-box-shadow: 0 0 0 4px transparent;
}

.agp-button_primary > .agp-button__content {
  color: var(--gray-90);
}

.agp-button_primary:focus {
  box-shadow: 0 0 0 4 var(--focus);
  -webkit-box-shadow: 0 0 0 4px var(--focus);
  -moz-box-shadow: 0 0 0 4px var(--focus);
}

.agp-button_primary:hover {
  background-color: var(--corp-green-25);
}

.agp-button_primary:active {
  background-color: var(--corp-green-80);
}

.agp-button_primary .ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: var(--corp-green-80);
  transition: 0.3s;
}

.agp-button_secondary {
  background-color: var(--corp-green-10);
  box-shadow: 0 0 0 4px transparent;
  -webkit-box-shadow: 0 0 0 4px transparent;
  -moz-box-shadow: 0 0 0 4px transparent;
}

.agp-button_secondary > .agp-button__content {
  color: var(--corp-green-40);
}

.agp-button_secondary:focus {
  box-shadow: 0 0 0 4px var(--focus);
  -webkit-box-shadow: 0 0 0 4px var(--focus);
  -moz-box-shadow: 0 0 0 4px var(--focus);
}

.agp-button_secondary:hover {
  background-color: var(--corp-green-20);
}

.agp-button_secondary:active {
  background-color: var(--corp-green-30);
}

.agp-button_secondary .ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: var(--corp-green-30);
}

.agp-button_ghost,
.agp-button_text {
  background-color: transparent;
  box-shadow: 0 0 0 4px transparent;
  -webkit-box-shadow: 0 0 0 4px transparent;
  -moz-box-shadow: 0 0 0 4px transparent;  
}

.agp-button_ghost > .agp-button__content,
.agp-button_text > .agp-button__content {
  color: var(--black);
  transition: all .3s;
}

.agp-button_ghost:focus,
.agp-button_text:focus {
  box-shadow: 0 0 0 4px var(--focus);
  -webkit-box-shadow: 0 0 0 4px var(--focus);
  -moz-box-shadow: 0 0 0 4px var(--focus);
}

.agp-button_ghost:hover > .agp-button__content,
.agp-button_text:hover > .agp-button__content {
  color: var(--corp-green-25);
}

.agp-button_ghost:active > .agp-button__content,
.agp-button_text:active > .agp-button__content {
  color: var(--corp-green);
}

.agp-button_ghost .ripple,
.agp-button_text .ripple {
  display: none;
}

.agp-button_whiteBordered {
  background-color: var(--white);
  border: 1px solid var(--gray-30);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.agp-button_whiteBordered:hover,
.agp-button_whiteBordered:active {
  background: var(--gray-05);
}

.agp-button_whiteBordered:focus {
  box-shadow: 0 0 0 4px var(--focus);
  -webkit-box-shadow: 0 0 0 4px var(--focus);
}

.agp-button_whiteBordered .ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: var(--gray-10);
}

.agp-button_primary:disabled,
.agp-button_secondary:disabled,
.agp-button_ghost:disabled,
.agp-button_text:disabled,
.agp-button_whiteBordered:disabled {
  cursor: not-allowed;
  pointer-events: auto;
  background-color: var(--gray-10);
  box-shadow: 0 0 0 4px transparent;
  -webkit-box-shadow: 0 0 0 4px transparent;
  -moz-box-shadow: 0 0 0 4px transparent;
}

.agp-btn-primary--disabled:disabled {
  cursor: auto;
  background-color: var(--corp-green-60);
}

.agp-button_ghost:disabled,
.agp-button_text:disabled {
  background: none;
}

.agp-button_whiteBordered:disabled {
  color: var(--gray-60);
  border: 1px solid var(--gray-30);
}

.agp-button_primary:disabled > .agp-button__content,
.agp-button_secondary:disabled > .agp-button__content,
.agp-button_ghost:disabled > .agp-button__content,
.agp-button_text:disabled > .agp-button__content {
  color: var(--gray-60);
}

.agp-btn-primary--disabled:disabled .agp-button__content {
  color: var(--white);
}

.agp-button_56 {
  $height: 56px;
  height: $height;
  padding: 0 40px;

  &.agp-button_square {
    width: $height;
    padding: 0;

    .agp-button__icon_start {
      margin-right: 0;
    }
  }
}

.agp-button_56 > .agp-button__content {
  font-size: 18px;
  line-height: 28px;
}

.agp-button_48 {
  $height: 48px;
  height: $height;
  padding: 0 32px;

  &.agp-button_square {
    width: $height;
    padding: 0;

    .agp-button__icon_start {
      margin-right: 0;
    }
  }
}

.agp-button_48 > .agp-button__content {
  font-size: 16px;
  line-height: 24px;
}

.agp-button_40 {
  $height: 40px;
  min-height: $height;
  padding: 0 24px;

  &.agp-button_square {
    width: $height;
    padding: 0;

    .agp-button__icon_start {
      margin-right: 0;
    }
  }
}

.agp-button_40 > .agp-button__content {
  font-size: 16px;
  line-height: 24px;
}

.agp-button_32 {
  $height: 32px;
  min-height: $height;
  padding: 0 16px;

  &.agp-button_square {
    width: $height;
    padding: 0;

    .agp-button__icon_start {
      margin-right: 0;
    }
  }
}

.agp-button_32 > .agp-button__content {
  font-size: 14px;
  line-height: 20px;
}

.agp-button_whiteBordered.agp-button_32 > .agp-button__content {
  font-family: Golos, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.agp-button_icon {
  // TODO уточнить как лучше добавить размер?
  padding: 16px;
}

.agp-button__fetching .agp-button__content {
  opacity: 0;
}
.agp-button__text-left .agp-button__content {
  width: 100%;
  text-align: left;
}

.agp-button__fetching {
  position: relative;
}

.agp-button__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .agp-button_square {
//   background-color: red;
//   padding: 0;
//   width: $height;
// }

.agp-button__content {
  z-index: 1;
}

.agp-button__loader {
  display: flex;
  justify-content: center;
  align-self: center;
}

.agp-button__icon {
  display: inline-flex;
  align-items: center;
}

.agp-button__icon_start {
  margin-right: 10px;
}

.agp-button__icon_end {
  margin-left: 8px;
  vertical-align: middle;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.agp-button_text {
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  line-height: 24px;
  height: fit-content;
}

.projects-table {
    tr {
        th,
        td {            
            &.td-btn {
                width: 0;       
                min-width: fit-content;         
                // max-width: 40px;
                // min-width: 45px;
            }
            &:nth-child(1) {
                width: 40px;
                max-width: 40px;
                min-width: 40px;
            }
            &.name {
                // width: 40%;
                // max-width: 40%;
                max-width: 35vw;
                width: 35vw;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 576px) {
                    max-width: 65vw;
                }
            }
            &.address {
                // width: calc(60% - 40px);
                // max-width: calc(60% - 40px);
                max-width: 50vw;
                width: 50vw;
                overflow: hidden;
                text-overflow: ellipsis;
                @media screen and (max-width: 576px) {
                    max-width: 40vw;
                }
            }
        }
    }
}
.row-center-justify {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.button-add {
    svg {
        margin-right: 10px;
    }
}

.agp-formBlock_row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
}

.agp-formBlock_column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.agp-formBlock_withTopBorder {
  padding-top: 32px;
  border-top: 1px solid var(--gray-20);
  &.agp-formBlock__verticalPaddings_small {
    padding-top: 12px;
  }
}

.agp-formBlock_withBottomBorder {
  padding-bottom: 32px;
  border-bottom: 1px solid var(--gray-20);
  &.agp-formBlock__verticalPaddings_small {
    padding-bottom: 12px;
  }
}
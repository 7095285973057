.statuses {
    padding-top: 10px;
    &__status {
        display: flex;
        align-items: center;
        transition: all 0.3s;
        position: relative;
        border-bottom: 1px solid var(--gray-20);
        padding: 5px 0;
        border-left: 3px solid var(--gray-20);
        padding-left: 10px;
        svg {
            transition: all 0.3s !important;
            cursor: pointer;
            * {
                transition: all 0.3s;
            }
        }
        &:hover {
            z-index: 2;
            box-shadow: 0 2px 4px rgba(0, 20, 67, 0.08);
            svg {
                path {
                    fill: var(--error-60);
                }
            }
        }
        &__name {
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 5px;
        }
    }
}
.start-type{
    display: block;
    width: 100%;
    text-transform: lowercase;
}
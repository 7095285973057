.button-back {
    display: flex;
    align-items: center;
    color: var(--gray-60);
    &:hover {
        color: var(--corp-green);
        svg {
            stroke: var(--corp-green);
        }
    }
    svg {
        stroke: var(--gray-60);
        transition: all 0.3s;
        padding-right: 10px;
    }
}

.agp-notification {
  display: flex;
  padding: 16px;
  border: 2px solid var(--gray-20);
  box-sizing: border-box;
  background-color: var(--white);
  box-shadow: inset 0px 4px 0px transparent;
  border-radius: 4px;
}

.agp-notification__content,
.agp-notification .agp-documentsContentItem__fieldName,
.agp-notification .agp-documentsContentItem__fieldValue {
  font-size: 14px;
  line-height: 20px;
}

.agp-notification .agp-documentsContentItem {
  margin-bottom: 12px;
}

.agp-notification__body {
  width: 100%;
}

.agp-notification_headless .agp-notification__body {
  display: flex;
  align-items: center;
}


.agp-notification_error {
  border-top: 4px solid var(--error-60);
}

.agp-notification_success {
  border-top: 4px solid var(--success-60);
}

.agp-notification_warning {
  border-top: 4px solid var(--warning-60);
}

.agp-notification_information {
  border-top: 4px solid var(--blue-60);
}

.agp-notification__header {
  font-family: Golos, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-90);
  margin-bottom: 16px;
}


.agp-notification__header {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.agp-notification__headerContent {
  margin-left: 14px;
}

.agp-notification__content {
  margin-left: 42px;
}

.agp-notification_validation .agp-notification__content {
  margin-left: 32px;
}


.agp-notification_headless .agp-notification__content {
  margin-left: 14px;
}

.agp-notification__iconWrapper {
  line-height: 1;
}

.agp-notification__icon {
  border-radius: 50%;
  position: relative;
}


.agp-notification__button {
  margin-top: 8px;
  margin-left: 42px;
}

.agp-notification_validation .agp-notification__button {
  margin-left: 32px;
}


.agp-notification_validation {
  border: none;
  border-radius: 0;
}

.agp-notification_validation.agp-notification_error {
  border-left: 4px solid var(--error-60);
  background-color: #FFEBEB;

}

.agp-notification_validation.agp-notification_success {
  border-left: 4px solid var(--success-60);
  background-color: var(--success-05);
}

.agp-notification_validation.agp-notification_warning {
  border-left: 4px solid var(--warning-60);
  background-color: #FFF7E6;
}

.agp-notification_validation.agp-notification_information {
  border-left: 4px solid var(--blue-60);
  background-color: var(--blue-05);
}

.agp-notification__closeButton {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-self: start;
}

@media (max-width: 400px) {
  .agp-notification {
    position: relative;
  }

  .agp-notification__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .agp-notification__closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .agp-notification__headerContent {
    margin-left: 42px;
  }

  .agp-notification_validation .agp-notification__button,
  // .agp-notification_validation .agp-notification__content,
  .agp-notification_validation .agp-notification__headerContent {
    margin-left: 0;
  }

  .agp-notification__iconWrapper {
    margin-bottom: 10px;
  }
}


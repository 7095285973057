.react-datepicker {
  padding: 16px !important;
  font-weight: 400;

  .react-datepicker__header--custom {
    background-color: #fff;
    padding: 0;
    border-bottom: none;
  }

  .react-datepicker__day-name {
    text-transform: capitalize;
    color: var(--gray-60);
    font-family: Golos, sans-serif;
    font-size: 14px;
    line-height: 20px;
    width: 40px;
    margin: 0;
  }

  .react-datepicker__month, .react-datepicker__year {
    margin-left: 0;
    margin-right: 0;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
  }

  .react-datepicker__week, .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__week > div {
    padding: 0;
    width: 40px;
    height: 40px;
   }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__month-text {
    width: 93.33333px !important;
    height: 56px !important;
  }

  .react-datepicker__month-text, .react-datepicker__year-text, .react-datepicker__week > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-family: GolosText, Golos, sans-serif;
    color: var(--gray-90);
    font-size: 14px;
    line-height: 20px;
    margin: 0 !important;
    border-radius: 0 !important;
    background: var(--white);
    text-transform: capitalize;
    position: relative;
    &::before {
      content: '';
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      position: absolute;
      box-sizing: border-box;
      outline: 1px solid var(--gray-20);
      left: 0;
      top: 0;
    }
  }

  .react-datepicker__month-text:hover, .react-datepicker__year-text:hover, .react-datepicker__week > div:hover {
    background-color: var(--corp-green-10);
  }

  .react-datepicker__day--selected, .react-datepicker__month--selected, .react-datepicker__year-text--selected {
    background-color: var(--corp-green) !important;
    color: var(--white) !important;
  }

  .react-datepicker__day--disabled, .react-datepicker__month--disabled, .react-datepicker__year-text--disabled {
    background-color: var(--gray-10) !important;
    color: var(--gray-60) !important;
  }

  .react-datepicker__monthPicker {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .react-datepicker__year-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 280px;
  }

  .react-datepicker__year-text {
    min-width: 70px;
    min-height: 45px;
    max-width: 70px;
    max-height: 45px;
    width: 70px !important;
    height: 45px !important;
  }

  .react-datepicker__week:last-child {
    display: none;
  }
}

@media(max-width: 340px) {
  .react-datepicker{
    padding: 15px 6px !important;
  }
}
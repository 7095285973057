.agp-select__input {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  // margin-top: 8px;
  width: 100%;
}

.field_required {
  .agp-select__label {
    &::after {
      content: "*";
      color: var(--error-60);
      font-size: 15pt;
      line-height: 1;
      font-weight: bold;
    }
  }
}
.agp-select__input_error {
  .agp-select__input__button {
    border-color: var(--error-60);
  }
}

.agp-select_small .agp-select__input {
  width: 160px;
}

.agp-select_default .agp-select__input {
  width: 240px;
}

.agp-select_halfWidth .agp-select__input {
  width: 50%;
}

.agp-select_fullWidth .agp-select__input {
  width: 100%;
}

.agp-select__input__button {
  text-align: left;
  margin: 0;
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--gray-30);
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 160px;
  min-height: 48px;
  background: var(--white);
  font-size: 16px;
  padding: 11px 24px 11px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.agp-select__label {
  font-family: Golos, sans-serif;
  color: var(--gray-90);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: inline-flex;
  // margin: 4px 0px;
  margin-bottom: 8px;
  cursor: pointer;
}
.agp-select__input__button:focus {
  box-shadow: 0 0 0 3px var(--focus);
  outline: none;
}
.agp-select__input__list {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  z-index: 100;
  padding: 4px 0px;
  width: 100%;

  background: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 12px rgba(0, 20, 67, 0.16);
  border-radius: 8px;

  list-style: none;

  position: absolute;
  left: 0;

  max-height: 500px;
  overflow-y: auto;
  transition: all 0.3s;
}
.agp-select__input__list:focus {
  outline-style: solid;
  outline-width: 4px;
  outline-color: #6688dd;
  outline-offset: 1px;
}
.agp-select__input__list__option {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 32px;
  width: 100%;
  padding-left: 24px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;  
}

.agp-select__input__list__option:first-letter,
.agp-select__input__button:first-letter {
  text-transform: uppercase;
}

.agp-select__input__list__option:hover,
.agp-select__input__list__option:focus {
  background-color: var(--corp-green);
  // color: var(--white);
}
.agp-select__input__icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
}
.agp-select__input__list__option__icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
}
.agp-select__input__list__option:hover .agp-select__input__list__option__icon path {
  fill: var(--white);
}
.agp-select__info {
  display: flex;
  color: var(--gray-80);
  font-size: 16px;
  font-family: GolosText, Golos, sans-serif;
  margin: 4px 0px;
}
.select__label_wrapper {
  display: flex;
  align-items: center;
  &.absolute-error{
    justify-content: space-between;
    .agp-balloon__wrapper-error{
      margin-bottom: 8px;
    }
  }
}

.agp-select__isValid {
  margin-left: 10px;
}
.agp-select__input__button:disabled {
  background-color: var(--gray-10);
  cursor: not-allowed;
  color: var(--gray-60);
}

@media (max-width: 480px) {
  .agp-select__input__list__option {
    padding-right: 24px;
  }

  .agp-select__input__list__option__icon {
    top: 16px;
  }
}
.agp-select {
  &.ghost {
    .agp-select__input__list__option:first-letter,
    .agp-select__input__button:first-letter {
      text-transform: none;
    }
    .agp-select__input {
      padding: 0;
      margin: 0;      
      .agp-select__input__button {
        
        min-width: unset;
        background: none;
        border: unset;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 24px;
        color: var(--white);
        position: relative;
        z-index: 3;
        span {
          width: 100%;
          text-overflow: ellipsis;
        }
      }
      .agp-select__input__list__option {
        color: var(--black);
        line-height: 1.1;
        padding-top: 5px;
        padding-bottom: 5px;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

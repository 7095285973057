.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.ngx-progress-bar[_ngcontent-votings-c1] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 99999 !important;
  display: none;
  color: #00acc1;
  overflow: hidden;
}
.ngx-progress-bar.foreground-closing[_ngcontent-votings-c1],
.ngx-progress-bar.loading-foreground[_ngcontent-votings-c1] {
  display: block;
}
.ngx-progress-bar.foreground-closing[_ngcontent-votings-c1] {
  opacity: 0 !important;
  transition: opacity 0.5s ease-out 0.5s;
}
.ngx-progress-bar[_ngcontent-votings-c1]::after,
.ngx-progress-bar[_ngcontent-votings-c1]::before {
  background-color: currentColor;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.ngx-progress-bar-ltr[_ngcontent-votings-c1]::before {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.ngx-progress-bar-ltr[_ngcontent-votings-c1]::after {
  -webkit-animation: 20s ease-out progressBar-slide-ltr;
  animation: 20s ease-out progressBar-slide-ltr;
  -webkit-transform: translate3d(-5%, 0, 0);
  transform: translate3d(-5%, 0, 0);
}
.ngx-progress-bar-rtl[_ngcontent-votings-c1]::before {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.ngx-progress-bar-rtl[_ngcontent-votings-c1]::after {
  -webkit-animation: 20s ease-out progressBar-slide-rtl;
  animation: 20s ease-out progressBar-slide-rtl;
  -webkit-transform: translate3d(5%, 0, 0);
  transform: translate3d(5%, 0, 0);
}
.foreground-closing.ngx-progress-bar-ltr[_ngcontent-votings-c1]::before {
  -webkit-animation: 1s ease-out progressBar-slide-complete-ltr;
  animation: 1s ease-out progressBar-slide-complete-ltr;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.foreground-closing.ngx-progress-bar-rtl[_ngcontent-votings-c1]::before {
  -webkit-animation: 1s ease-out progressBar-slide-complete-rtl;
  animation: 1s ease-out progressBar-slide-complete-rtl;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes progressBar-slide-ltr {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }
}
@keyframes progressBar-slide-ltr {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }
}
@-webkit-keyframes progressBar-slide-rtl {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }
}
@keyframes progressBar-slide-rtl {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }
}
@-webkit-keyframes progressBar-slide-complete-ltr {
  0% {
    -webkit-transform: translate3d(-75%, 0, 0);
    transform: translate3d(-75%, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes progressBar-slide-complete-ltr {
  0% {
    -webkit-transform: translate3d(-75%, 0, 0);
    transform: translate3d(-75%, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes progressBar-slide-complete-rtl {
  0% {
    -webkit-transform: translate3d(75%, 0, 0);
    transform: translate3d(75%, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes progressBar-slide-complete-rtl {
  0% {
    -webkit-transform: translate3d(75%, 0, 0);
    transform: translate3d(75%, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.ngx-overlay[_ngcontent-votings-c1] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99998 !important;
  background-color: rgba(40, 40, 40, 0.8);
  cursor: progress;
}
.ngx-overlay.foreground-closing[_ngcontent-votings-c1],
.ngx-overlay.loading-foreground[_ngcontent-votings-c1] {
  display: block;
}
.ngx-overlay.foreground-closing[_ngcontent-votings-c1] {
  opacity: 0 !important;
  transition: opacity 0.5s ease-out 0.5s;
}
.ngx-overlay[_ngcontent-votings-c1] > .ngx-foreground-spinner[_ngcontent-votings-c1] {
  position: fixed;
  width: 60px;
  height: 60px;
  margin: 0;
  color: #00acc1;
}
.ngx-overlay[_ngcontent-votings-c1] > .ngx-loading-logo[_ngcontent-votings-c1] {
  position: fixed;
  margin: 0;
  width: 120px;
  height: 120px;
}
.ngx-overlay[_ngcontent-votings-c1] > .ngx-loading-text[_ngcontent-votings-c1] {
  position: fixed;
  margin: 0;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  color: #fff;
}
.ngx-background-spinner[_ngcontent-votings-c1] {
  position: fixed;
  z-index: 99997 !important;
  width: 60px;
  height: 60px;
  margin: 0;
  color: #00acc1;
  opacity: 0.6;
  display: none;
}
.ngx-background-spinner.background-closing[_ngcontent-votings-c1],
.ngx-background-spinner.loading-background[_ngcontent-votings-c1] {
  display: block;
}
.ngx-background-spinner.background-closing[_ngcontent-votings-c1] {
  opacity: 0 !important;
  transition: opacity 0.7s ease-out;
}
.ngx-position-absolute[_ngcontent-votings-c1],
.ngx-position-absolute[_ngcontent-votings-c1] > .ngx-foreground-spinner[_ngcontent-votings-c1],
.ngx-position-absolute[_ngcontent-votings-c1] > .ngx-loading-logo[_ngcontent-votings-c1],
.ngx-position-absolute[_ngcontent-votings-c1] > .ngx-loading-text[_ngcontent-votings-c1] {
  position: absolute !important;
}
.ngx-position-absolute.ngx-progress-bar[_ngcontent-votings-c1] {
  z-index: 99996 !important;
}
.ngx-position-absolute.ngx-overlay[_ngcontent-votings-c1] {
  z-index: 99995 !important;
}
.ngx-position-absolute[_ngcontent-votings-c1]
  .sk-square-jelly-box[_ngcontent-votings-c1]
  > div[_ngcontent-votings-c1]:nth-child(1),
.ngx-position-absolute.ngx-background-spinner[_ngcontent-votings-c1] {
  z-index: 99994 !important;
}
.top-left[_ngcontent-votings-c1] {
  top: 30px;
  left: 30px;
}
.top-center[_ngcontent-votings-c1] {
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.top-right[_ngcontent-votings-c1] {
  top: 30px;
  right: 30px;
}
.center-left[_ngcontent-votings-c1] {
  top: 50%;
  left: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.center-center[_ngcontent-votings-c1] {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.center-right[_ngcontent-votings-c1] {
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.bottom-left[_ngcontent-votings-c1] {
  bottom: 30px;
  left: 30px;
}
.bottom-center[_ngcontent-votings-c1] {
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.bottom-right[_ngcontent-votings-c1] {
  bottom: 30px;
  right: 30px;
}
.sk-ball-scale-multiple[_ngcontent-votings-c1],
.sk-ball-scale-multiple[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-ball-scale-multiple[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.sk-ball-scale-multiple[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: 1s linear infinite ball-scale-multiple;
  animation: 1s linear infinite ball-scale-multiple;
}
.sk-ball-scale-multiple[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-ball-scale-multiple[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
@-webkit-keyframes ball-scale-multiple {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 0.75;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-scale-multiple {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  5% {
    opacity: 0.75;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-ball-spin[_ngcontent-votings-c1],
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-ball-spin[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  height: 25%;
  margin-top: -12.5%;
  margin-left: -12.5%;
  border-radius: 100%;
  -webkit-animation: 1s ease-in-out infinite ball-spin-clockwise;
  animation: 1s ease-in-out infinite ball-spin-clockwise;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -1.125s;
  animation-delay: -1.125s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -1.375s;
  animation-delay: -1.375s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -1.625s;
  animation-delay: -1.625s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -1.75s;
  animation-delay: -1.75s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -1.875s;
  animation-delay: -1.875s;
}
.sk-ball-spin[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}
@-webkit-keyframes ball-spin {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes ball-spin {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1],
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  height: 25%;
  margin-top: -12.5%;
  margin-left: -12.5%;
  border-radius: 100%;
  -webkit-animation: 1s ease-in-out infinite ball-spin-clockwise;
  animation: 1s ease-in-out infinite ball-spin-clockwise;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -0.875s;
  animation-delay: -0.875s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -0.625s;
  animation-delay: -0.625s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -0.375s;
  animation-delay: -0.375s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -0.125s;
  animation-delay: -0.125s;
}
.sk-ball-spin-clockwise[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@-webkit-keyframes ball-spin-clockwise {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes ball-spin-clockwise {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1],
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] {
  font-size: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: 6s linear infinite ball-spin-clockwise-fade-rotating-rotate;
  animation: 6s linear infinite ball-spin-clockwise-fade-rotating-rotate;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  height: 25%;
  margin-top: -12.5%;
  margin-left: -12.5%;
  border-radius: 100%;
  -webkit-animation: 1s linear infinite ball-spin-clockwise-fade-rotating;
  animation: 1s linear infinite ball-spin-clockwise-fade-rotating;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -0.875s;
  animation-delay: -0.875s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -0.625s;
  animation-delay: -0.625s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -0.375s;
  animation-delay: -0.375s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -0.125s;
  animation-delay: -0.125s;
}
.sk-ball-spin-clockwise-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
@-webkit-keyframes ball-spin-clockwise-fade-rotating-rotate {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes ball-spin-clockwise-fade-rotating-rotate {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes ball-spin-clockwise-fade-rotating {
  50% {
    opacity: 0.25;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-spin-clockwise-fade-rotating {
  50% {
    opacity: 0.25;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1],
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  font-size: 0;
  -webkit-animation: 6s linear infinite ball-spin-fade-rotate;
  animation: 6s linear infinite ball-spin-fade-rotate;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  height: 25%;
  margin-top: -12.5%;
  margin-left: -12.5%;
  border-radius: 100%;
  -webkit-animation: 1s linear infinite ball-spin-fade;
  animation: 1s linear infinite ball-spin-fade;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -1.125s;
  animation-delay: -1.125s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -1.375s;
  animation-delay: -1.375s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -1.625s;
  animation-delay: -1.625s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -1.75s;
  animation-delay: -1.75s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -1.875s;
  animation-delay: -1.875s;
}
.sk-ball-spin-fade-rotating[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}
@-webkit-keyframes ball-spin-fade-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes ball-spin-fade-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ball-spin-fade {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.25;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes ball-spin-fade {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.25;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.sk-chasing-dots[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  -webkit-animation: 2s linear infinite sk-chasingDots-rotate;
  animation: 2s linear infinite sk-chasingDots-rotate;
}
.sk-chasing-dots[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: currentColor;
  border-radius: 100%;
  -webkit-animation: 2s ease-in-out infinite sk-chasingDots-bounce;
  animation: 2s ease-in-out infinite sk-chasingDots-bounce;
}
.sk-chasing-dots[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-chasingDots-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes sk-chasingDots-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chasingDots-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-chasingDots-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-circle[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: currentColor;
  border-radius: 100%;
  -webkit-animation: 1.2s ease-in-out infinite both sk-circle-bounceDelay;
  animation: 1.2s ease-in-out infinite both sk-circle-bounceDelay;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2)::before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3)::before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4)::before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5)::before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6)::before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7)::before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8)::before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(9)::before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(10)::before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(11)::before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(12)::before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-circle-bounceDelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circle-bounceDelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-cube-grid[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  margin: auto;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 33%;
  height: 33%;
  background-color: currentColor;
  float: left;
  -webkit-animation: 1.3s ease-in-out infinite sk-cubeGrid-scaleDelay;
  animation: 1.3s ease-in-out infinite sk-cubeGrid-scaleDelay;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(9) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@-webkit-keyframes sk-cubeGrid-scaleDelay {
  0%,
  100%,
  70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes sk-cubeGrid-scaleDelay {
  0%,
  100%,
  70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
.sk-double-bounce[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}
.sk-double-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: 2s ease-in-out infinite sk-doubleBounce-bounce;
  animation: 2s ease-in-out infinite sk-doubleBounce-bounce;
}
.sk-double-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-doubleBounce-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-doubleBounce-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-fading-circle[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: currentColor;
  border-radius: 100%;
  -webkit-animation: 1.2s ease-in-out infinite both sk-fadingCircle-FadeDelay;
  animation: 1.2s ease-in-out infinite both sk-fadingCircle-FadeDelay;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2)::before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3)::before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4)::before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5)::before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(6)::before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(7)::before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(8)::before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(9)::before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(10)::before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(11)::before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(12)::before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-fadingCircle-FadeDelay {
  0%,
  100%,
  39% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes sk-fadingCircle-FadeDelay {
  0%,
  100%,
  39% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.sk-folding-cube[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  -webkit-animation: 2.4s linear infinite both sk-foldingCube-angle;
  animation: 2.4s linear infinite both sk-foldingCube-angle;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2)::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3)::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.sk-folding-cube[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4)::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
@-webkit-keyframes sk-foldingCube-angle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0);
    transform: perspective(140px) rotateX(0);
    opacity: 1;
  }
  100%,
  90% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldingCube-angle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0);
    transform: perspective(140px) rotateX(0);
    opacity: 1;
  }
  100%,
  90% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
.sk-pulse[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  margin: auto;
}
.sk-pulse[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  background-color: currentColor;
  border-radius: 100%;
  -webkit-animation: 1s ease-in-out infinite sk-pulse-scaleOut;
  animation: 1s ease-in-out infinite sk-pulse-scaleOut;
}
@-webkit-keyframes sk-pulse-scaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-pulse-scaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.sk-rectangle-bounce[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;
}
.sk-rectangle-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  background-color: currentColor;
  height: 100%;
  width: 10%;
  margin: 0 5%;
  display: inline-block;
  -webkit-animation: 1.2s ease-in-out infinite sk-rectangleBounce-stretchDelay;
  animation: 1.2s ease-in-out infinite sk-rectangleBounce-stretchDelay;
}
.sk-rectangle-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-rectangle-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-rectangle-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-rectangle-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-rectangleBounce-stretchDelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes sk-rectangleBounce-stretchDelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1],
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  width: 10%;
  height: 100%;
  margin: 0 5%;
  border-radius: 0;
  -webkit-animation-name: rectangle-bounce-party;
  animation-name: rectangle-bounce-party;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  -webkit-animation-duration: 0.43s;
  animation-duration: 0.43s;
  -webkit-animation-delay: -0.23s;
  animation-delay: -0.23s;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-duration: 0.62s;
  animation-duration: 0.62s;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-animation-duration: 0.43s;
  animation-duration: 0.43s;
  -webkit-animation-delay: -0.44s;
  animation-delay: -0.44s;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-delay: -0.31s;
  animation-delay: -0.31s;
}
.sk-rectangle-bounce-party[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-animation-duration: 0.74s;
  animation-duration: 0.74s;
  -webkit-animation-delay: -0.24s;
  animation-delay: -0.24s;
}
@-webkit-keyframes rectangle-bounce-party {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
}
@keyframes rectangle-bounce-party {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  50% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
}
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1],
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;
}
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  width: 10%;
  height: 100%;
  margin: 0 5%;
  border-radius: 0;
  -webkit-animation: 0.9s cubic-bezier(0.85, 0.25, 0.37, 0.85) infinite rectangle-bounce-pulse-out;
  animation: 0.9s cubic-bezier(0.85, 0.25, 0.37, 0.85) infinite rectangle-bounce-pulse-out;
}
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2),
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1),
.sk-rectangle-bounce-pulse-out[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
@-webkit-keyframes rectangle-bounce-pulse-out {
  0%,
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
}
@keyframes rectangle-bounce-pulse-out {
  0%,
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
}
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1],
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;
}
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
  width: 10%;
  height: 100%;
  margin: 0 5%;
  border-radius: 0;
  -webkit-animation: 0.9s cubic-bezier(0.11, 0.49, 0.38, 0.78) infinite rectangle-bounce-pulse-out-rapid;
  animation: 0.9s cubic-bezier(0.11, 0.49, 0.38, 0.78) infinite rectangle-bounce-pulse-out-rapid;
}
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2),
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(4) {
  -webkit-animation-delay: -0.65s;
  animation-delay: -0.65s;
}
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1),
.sk-rectangle-bounce-pulse-out-rapid[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(5) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
@-webkit-keyframes rectangle-bounce-pulse-out-rapid {
  0%,
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  80% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
}
@keyframes rectangle-bounce-pulse-out-rapid {
  0%,
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  80% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
}
.sk-rotating-plane[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
}
.sk-rotating-plane[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  background-color: currentColor;
  -webkit-animation: 1.2s ease-in-out infinite sk-rotatePlane;
  animation: 1.2s ease-in-out infinite sk-rotatePlane;
}
@-webkit-keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.sk-square-jelly-box[_ngcontent-votings-c1],
.sk-square-jelly-box[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-square-jelly-box[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.sk-square-jelly-box[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.sk-square-jelly-box[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1),
.sk-square-jelly-box[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  position: absolute;
  left: 0;
  width: 100%;
}
.sk-square-jelly-box[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  top: -25%;
  z-index: 99997;
  height: 100%;
  border-radius: 10%;
  -webkit-animation: 0.6s linear -0.1s infinite square-jelly-box-animate;
  animation: 0.6s linear -0.1s infinite square-jelly-box-animate;
}
.sk-square-jelly-box[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  bottom: -9%;
  height: 10%;
  background: #000;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-animation: 0.6s linear -0.1s infinite square-jelly-box-shadow;
  animation: 0.6s linear -0.1s infinite square-jelly-box-shadow;
}
@-webkit-keyframes square-jelly-box-animate {
  17% {
    border-bottom-right-radius: 10%;
  }
  25% {
    -webkit-transform: translateY(25%) rotate(22.5deg);
    transform: translateY(25%) rotate(22.5deg);
  }
  50% {
    border-bottom-right-radius: 100%;
    -webkit-transform: translateY(50%) scale(1, 0.9) rotate(45deg);
    transform: translateY(50%) scale(1, 0.9) rotate(45deg);
  }
  75% {
    -webkit-transform: translateY(25%) rotate(67.5deg);
    transform: translateY(25%) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes square-jelly-box-animate {
  17% {
    border-bottom-right-radius: 10%;
  }
  25% {
    -webkit-transform: translateY(25%) rotate(22.5deg);
    transform: translateY(25%) rotate(22.5deg);
  }
  50% {
    border-bottom-right-radius: 100%;
    -webkit-transform: translateY(50%) scale(1, 0.9) rotate(45deg);
    transform: translateY(50%) scale(1, 0.9) rotate(45deg);
  }
  75% {
    -webkit-transform: translateY(25%) rotate(67.5deg);
    transform: translateY(25%) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@-webkit-keyframes square-jelly-box-shadow {
  50% {
    -webkit-transform: scale(1.25, 1);
    transform: scale(1.25, 1);
  }
}
@keyframes square-jelly-box-shadow {
  50% {
    -webkit-transform: scale(1.25, 1);
    transform: scale(1.25, 1);
  }
}
.sk-square-loader[_ngcontent-votings-c1],
.sk-square-loader[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: relative;
  box-sizing: border-box;
}
.sk-square-loader[_ngcontent-votings-c1] {
  font-size: 0;
  width: 100%;
  height: 100%;
}
.sk-square-loader[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  display: inline-block;
  float: none;
  border: 3px solid currentColor;
  width: 100%;
  height: 100%;
  background: 0 0;
  border-radius: 0;
  -webkit-animation: 2s infinite square-loader;
  animation: 2s infinite square-loader;
}
.sk-square-loader[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:after {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  content: "";
  background-color: currentColor;
  -webkit-animation: 2s ease-in infinite square-loader-inner;
  animation: 2s ease-in infinite square-loader-inner;
}
@-webkit-keyframes square-loader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25%,
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100%,
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes square-loader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  25%,
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100%,
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes square-loader-inner {
  0%,
  100%,
  25% {
    height: 0;
  }
  50%,
  75% {
    height: 100%;
  }
}
@keyframes square-loader-inner {
  0%,
  100%,
  25% {
    height: 0;
  }
  50%,
  75% {
    height: 100%;
  }
}
.sk-three-bounce[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  margin-top: 35%;
  width: 30%;
  height: 30%;
  background-color: currentColor;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: 1.4s ease-in-out infinite both sk-threeBounce-bounceDelay;
  animation: 1.4s ease-in-out infinite both sk-threeBounce-bounceDelay;
}
.bottom-center[_ngcontent-votings-c1] > .sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1],
.bottom-left[_ngcontent-votings-c1] > .sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1],
.bottom-right[_ngcontent-votings-c1] > .sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  margin-top: 70% !important;
}
.top-center[_ngcontent-votings-c1] > .sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1],
.top-left[_ngcontent-votings-c1] > .sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1],
.top-right[_ngcontent-votings-c1] > .sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  margin-top: 0 !important;
}
.sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.sk-three-bounce[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-threeBounce-bounceDelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-threeBounce-bounceDelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.sk-three-strings[_ngcontent-votings-c1] {
  width: 100%;
  height: 100%;
}
.sk-three-strings[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.sk-three-strings[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(1) {
  left: 0;
  top: 0;
  -webkit-animation: 1s linear infinite sk-threeStrings-rotateOne;
  animation: 1s linear infinite sk-threeStrings-rotateOne;
  border-bottom: 3px solid currentColor;
}
.sk-three-strings[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  right: 0;
  top: 0;
  -webkit-animation: 1s linear infinite sk-threeStrings-rotateTwo;
  animation: 1s linear infinite sk-threeStrings-rotateTwo;
  border-right: 3px solid currentColor;
}
.sk-three-strings[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation: 1s linear infinite sk-threeStrings-rotateThree;
  animation: 1s linear infinite sk-threeStrings-rotateThree;
  border-top: 3px solid currentColor;
}
@-webkit-keyframes sk-threeStrings-rotateOne {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes sk-threeStrings-rotateOne {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-webkit-keyframes sk-threeStrings-rotateTwo {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes sk-threeStrings-rotateTwo {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-webkit-keyframes sk-threeStrings-rotateThree {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@keyframes sk-threeStrings-rotateThree {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.sk-wandering-cubes[_ngcontent-votings-c1] {
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}
.sk-wandering-cubes[_ngcontent-votings-c1] > div[_ngcontent-votings-c1] {
  background-color: currentColor;
  width: 25%;
  height: 25%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: 1.8s ease-in-out infinite sk-wanderingCubes-cubeMove;
  animation: 1.8s ease-in-out infinite sk-wanderingCubes-cubeMove;
}
.sk-wandering-cubes[_ngcontent-votings-c1] > div[_ngcontent-votings-c1]:nth-child(2) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
@-webkit-keyframes sk-wanderingCubes-cubeMove {
  25% {
    -webkit-transform: translateX(290%) rotate(-90deg) scale(0.5);
    transform: translateX(290%) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(290%) translateY(290%) rotate(-179deg);
    transform: translateX(290%) translateY(290%) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(290%) translateY(290%) rotate(-180deg);
    transform: translateX(290%) translateY(290%) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(290%) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(290%) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes sk-wanderingCubes-cubeMove {
  25% {
    -webkit-transform: translateX(290%) rotate(-90deg) scale(0.5);
    transform: translateX(290%) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(290%) translateY(290%) rotate(-179deg);
    transform: translateX(290%) translateY(290%) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(290%) translateY(290%) rotate(-180deg);
    transform: translateX(290%) translateY(290%) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(290%) rotate(-270deg) scale(0.5);
    transform: translateX(0) translateY(290%) rotate(-270deg) scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.agp-modalForm {
  font-family: Golos, sans-serif;
  padding-left: 0 !important;
  padding-right: 0 !important;

}
.agp-modal_subtext{
  padding-left: 30px;
}
.agp-modalForm__content, .agp-modalForm__header, .agp-modalForm__bottomBlock {
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}

.agp-modalForm__header {
  padding-bottom: 24px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-20);
}

.agp-modalForm__header_string {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 24px;
}
.agp-modalForm__header-details{
  display: flex;
}

.agp-modalForm__header-subtitle {
  color: var(--gray-60);
  font-size: 14px;
  font-weight: 400;
}

.agp-modalForm__header-step-dash {
  margin: 0 8px;
  color: var(--gray-60);
}

.agp-modalForm__header-undertitle {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.agp-modalForm__header-undertitle-margin {
  margin-top: 16px;
}

.agp-modalForm__header-undertitle > span{
  margin-right: 8px;
  color:var(--gray-90);
  font-size: 16px;
  font-weight: 400;
}
.agp-modalForm__header-undertitle > a > button {
  height: inherit;
}
.agp-modalForm__header-subtitle-line{
  margin-left: 8px;
  margin-right: 8px;
}

.agp-modalForm__goBackButtonContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

// МОБИЛА
@media (max-width: 768px) {
  .agp-modalForm {
    padding-top:16px;
    padding-bottom: 24px;
  }

  .agp-modalForm__header {
    padding: 0 16px 16px;
    padding-right: 55px;
  }
  .agp-modalForm__header_string {
    font-size: 21px;
    line-height: 24px;
    padding-bottom: 16px;
    padding-right: 43px;
  }
  .agp-modalForm__content, .agp-modalForm__bottomBlock {
    //height: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 320px) {
  .agp-modalForm__header {
    padding-right: 16px;
  }

  .agp-modalForm__header.agp-modalForm__header_string {
    padding-right: 43px;
  }
}

.clipboard-field {
    display: flex;
    width: 100%;
    cursor: pointer;
    *{
        cursor: pointer;
    }
    &:hover {
        .icon {
            background: var(--corp-green);
        }
    }
    .agp-textfield__input {
        &:focus {
            border: 1px solid var(--gray-30);
            box-shadow: none;
        }

        width: 100%;
        border-radius: 0px 4px 4px 0px;
    }
    .icon {
        position: relative;
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid var(--gray-30);
        border-right: none;
        border-radius: 4px 0 0px 4px;
        background: var(--black-20);
        transition: all 0.3s;
    }
}

#main-page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    overflow: hidden;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: -100px;
        bottom: 10%;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: var(--corp-green);
    }
    .form-block__border {
        @media screen and (max-width: 576px) {
            width: 85%;
        }
    }
    .logo-half {
        position: relative;
        left: -50vh;
        height: 100vh;
        z-index: 1;
        margin-right: -20%;
        @media screen and (max-width: 1200px) {
            margin-right: -40%;
        }
        @media screen and (max-width: 1024px) {
            margin-right: -80%;
            left: -59vh;
        }
        @media screen and (max-width: 576px) {
            left: -50%;
            height: 66vh;
            top: -25vh;
        }
    }
    .form-block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 3;

        .LOGINFORM,
        .REGISTERFORM,
        .FORGOTFORM {
            background: var(--white-light);
            max-width: 400px;
            width: 400px;
            box-shadow:
                0px 3px 6px 0px rgba(0, 0, 0, 0.1),
                0px 1px 3px 0px rgba(0, 0, 0, 0.08);
            -webkit-box-shadow:
                0px 3px 6px 0px rgba(0, 0, 0, 0.1),
                0px 1px 3px 0px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            padding: 16px 24px;
            .form-caption {
                text-align: center;
                margin-top: 0;
            }
            @media screen and (max-width: 576px) {
                width: 100%;
            }
        }
        @media screen and (max-width: 900px) {
            position: fixed;
        }
    }
}

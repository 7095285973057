.agp-field-error {
  // display: flex;
  // align-items: flex-start;
  font-family: GolosText, Golos, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: var(--error-60);
  width: 100%;
  margin: 4px 0 8px 0;
  font-weight: 400;
  svg {
    line-height: 24px;
  }
  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
}

.agp-field-error__iconWrapper {
  min-height: 24px;
  height: 24px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  float: left;
}

.agp-field-error span {
  margin-left: 4px;
}

.agp-field-error-noMargin {
  margin: 0;
}


.agp-datepicker__wrapper {
  position: relative;
  width: 100%;
}
.agp-datepicker__label {
  display: block;
  font-family: Golos, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.agp-datepicker__input {
  font-family: Golos, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-90);
  resize: none;
  border: 1px solid var(--gray-40);
  border-radius: 4px;
  transition: border-color 0.1s ease-in-out;
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  padding: 12px;
  max-width: 160px;
}

.agp-datepicker__input:hover {
  border-color: var(--gray-40);
}

.agp-datepicker_error .agp-datepicker__input,
.agp-datepicker_error .agp-datepicker__input:hover {
  border-color: var(--error-60);
}

.agp-datepicker__input:focus,
.agp-datepicker_error .agp-datepicker__input:focus {
  box-shadow: 0 0 0 4px var(--focus);
  border: 1px solid var(--blue-60);
}

.agp-datepicker__input:disabled,
.agp-datepicker__input:disabled:hover {
  background-color: var(--gray-10);
  color: var(--gray-60);
  border-color: var(--gray-20);
  cursor: not-allowed;
}

.agp-datepicker__info {
  margin-top: 4px;
  margin-bottom: 8px;
  color: var(--gray-80);
  font-size: 14px;
}

.agp-datepicker__input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.agp-datepicker__endIcon {
  position: absolute;
  top: 50%;
  left: 168px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 12px 0 !important;

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent;
  }

  &:hover svg path {
    fill: var(--blue-60);
  }

  &:active svg path {
    fill: var(--blue-80);
  }
}

.agp-datepicker__endIcon-disabled {
  cursor: not-allowed !important;
  &:hover svg path {
    fill: var(--gray-40);
  }
}

.agp-datepicker__calendar {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  z-index: 1000;
}

.agp-datepicker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 16px;
}

.agp-datepicker__header-button {
  padding: 0;
  width: 32px;
}

.agp-datepicker__header-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agp-datepicker__header-links > div {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: Golos, sans-serif;
  text-align: center;
  text-transform: capitalize;
  margin-right: 5px;
  cursor: pointer;
  color: var(--black);
  transition: all 0.3s;
  &:hover {
    color: var(--corp-green);
  }
}

.agp-datepicker__header-links > div:last-child {
  margin-right: 0;
}

.react-datepicker__portal {
  background: rgba(0, 0, 0, 0.6);
}

// Мобила
@media (max-width: 768px) {
  .agp-datepicker__wrapper {
    max-width: 100%;
  }
}

@media (max-width: 360px) {
  .grid .agp-datepicker__calendar {
    left: -19px;
  }
}

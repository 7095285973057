.scroll-table {
    width: 100%;
    height: 90%;
    overflow: auto;
    z-index: 2;
    position: relative;
    table {
        border-spacing: 0;
        min-width: 100%;
        thead {
            background: var(--template-bg-grey);
            padding: 10px 15px;
            border-bottom: 1px solid #e2e2e2;
            font-weight: 700;
            position: -webkit-sticky;
            position: sticky;
            left: 0;
            top: 0;
            z-index: 7;
            th {
                background: var(--template-bg-grey);
                padding: 10px 5px;
                white-space: nowrap;
                text-align: left;
            }
        }
        tbody {
            tr {
                &:not(:last-child) {
                    border-bottom: 1px solid #e2e2e2;
                }
                td {
                    padding: 10px 5px;
                    background: #fff;
                    transition: all 0.3s;
                }
            }
        }
    }
}

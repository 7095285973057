.agp-circle__notification {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand);
  color: var(--white);

  &.type-large {
    font-size: 14px;
    height: 24px;
    min-width: 24px;
    border-radius: 14px;
  }

  &.type-small {
    height: 16px;
    min-width: 16px;
    margin-top: 0;
    font-size: 11px;
    border-radius: 8px;
  }
}

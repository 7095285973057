#work-area {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    // height: calc(100vh - 55px);
    // &.work_area__grid{
    //     display: grid;
    //     grid-template-rows: 100px 84vh;
    //     overflow: hidden;
    //     @media screen and (max-width: 576px) {
    //         grid-template-rows: 100px 82vh;
    //     }
    // }
    &.gray-white {
        .caption-block {
            background: var(--template-bg-grey);
            border-bottom: 1px solid var(--gray-30);
        }
        .content {
            background: var(--template-bg-white);
        }
    }
    &.white-gray {
        .caption-block {
            background: var(--template-bg-white);
            border-bottom: 1px solid var(--gray-30);
        }
        .content {
            background: var(--template-bg-grey);
        }
    }
    .caption-block {
        border-bottom: 1px solid #ededed;
        padding: 5px 3vw;
        // min-height: 100px;
        .page-menu {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            margin-bottom: -6px;
            li {
                list-style: none;
                transition: all 0.3s;
                border-bottom: 1px solid rgba(0, 0, 0, 0);
                &:not(:last-child){
                    margin-right: 10px;
                }
                &.active {
                    border-bottom: 1px solid var(--gray-90);
                    button {
                        * {
                            color: var(--gray-90) !important;
                        }
                    }
                }
                button {
                    * {
                        color: var(--gray-60) !important;
                        &:hover {
                            color: var(--gray-90) !important;
                        }
                    }
                }
                
            }
        }
    }
    .content {
        flex: 1;
        padding: 30px 3vw;
    }
}

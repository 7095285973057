.agp-link {
  font-family: Golos, sans-serif;
  text-decoration-line: underline;
  color: var(--corp-green);
  text-decoration-skip-ink: none;
  transition: all .3s;
}

.agp-link:focus {
  // outline: 4px solid var(--corp-green-80);
}

.agp-link:hover {
  color: var(--corp-green-70);
}

.agp-link:active {
  color: var(--corp-green-80);
}

.agp-link_pressed {
  color: var(--corp-green-80);
}

.agp-link_underline {
  text-decoration: none !important;
}

.autosuggest-field {
    width: 100%;
    position: relative;
}

.agp-textfield_small .agp-textfield__input {
    width: 160px;
}

.agp-textfield_default .agp-textfield__input {
    width: 240px;
}

.agp-textfield_halfWidth .agp-textfield__input {
    width: 50%;
}

.agp-textfield_fullWidth .agp-textfield__input {
    width: 100%;
}
.agp-textfield__label {
    display: block;
    font-family: Golos, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.agp-textfield__label-margin-8 {
    margin-bottom: 8px;
}
.agp-textfield__label-margin-4 {
    margin-bottom: 4px;
}
.autosuggest-field__content {
    position: relative;
    &.disabled {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 2;
            height: 100%;
            cursor: not-allowed;
        }
        .react-autosuggest__input {
            background-color: var(--gray-10);
            color: var(--gray-60);
            border-color: var(--gray-20);
        }
        .agp-button__loader {
            left: unset;
            right: 0;
        }
    }
}
.react-autosuggest__suggestions-container {
}
.react-autosuggest__suggestions-container--open {
    display: flex;
    flex-direction: column;
    max-height: 225px;
    overflow-y: auto;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translate(0, 8px);
    background-color: var(--white-light);
    z-index: 10000;
    border: 1px solid var(--gray-20);
    box-shadow: 0px 0px 1px rgba(14, 14, 15, 0.04), 0px 1px 4px rgba(14, 14, 15, 0.16);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    z-index: 9;
}
.react-autosuggest__suggestions-list {
    color: var(--gray-90);
    list-style: none;
    transition: all 0.3s;
    margin: 0;
    padding: 0;
    li {
        &:hover {
            background: var(--corp-green-10);
        }
        background: var(--white-light);
        box-sizing: border-box;
        margin: 0;
        display: flex;
        width: 100%;
        padding: 8px 16px;
        cursor: pointer;
    }
}
.react-autosuggest__container {
    width: 100%;
}
.react-autosuggest__input {
    width: 100%;
    height: 100%;
    font-family: Golos, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-90);
    resize: none;
    border: 1px solid var(--gray-30);
    border-radius: 4px;
    transition: border-color 0.1s ease-in-out;
    box-sizing: border-box;
    height: 48px;
    padding: 12px 15px;
    min-width: 160px;
}
.react-autosuggest__input:focus {
    box-shadow: 0px 0px 0px 4px var(--focus);
    border: 1px solid var(--green);
}
.react-autosuggest__container:hover {
    border-color: var(--gray-40);
}

.grid-block {
    background: #fff;
    padding: 15px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    border-radius: 4px;
    padding: 16px 24px;
}

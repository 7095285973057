.agp-tooltip {
  position: relative;
  display: inline-block;
}

.agp-tooltip__tip {
  position: absolute;
  border-radius: 4px;
  transform: translateX(-50%);
  padding: 10px 12px;
  color: var(--white);
  background: var(--gray-80);
  font-size: 14px;
  font-family: GolosText, Golos, sans-serif;
  z-index: 100;
  max-width: 288px;
  width: max-content;
}

.agp-tooltip__tip a {
  font-family: 'Golos';
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
}

.agp-tooltip__tip_top {
  top: -10px;
  left: 50%;
  transform: translate(-50%,-100%);
}

.agp-tooltip__tip_top-left {
  top: -10px;
  left: 0;
  transform: translate( 0,-100%);
}

.agp-tooltip__tip_top-right {
  top: -10px;
  right: 0;
  transform: translate( 0%, -100%);
}

.agp-tooltip__tip_bottom {
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, 100%);
}

.agp-tooltip__tip_bottom-left {
  left: 0;
  bottom: -10px;
  transform: translate(0%, 100%);
}

.agp-tooltip__tip_bottom-right {
  right: 0;
  bottom: -10px;
  transform: translate(0%, 100%);
}

@media (max-width: 768px) {
  .agp-tooltip {
    vertical-align: middle;
  }
}

@media (max-width: 360px) {
  .agp-tooltip__tip {
    max-width: 160px;
  }
}
.table-controll {
    display: flex;
    align-items: center;
    background: var(--black-20);
    padding: 5px;
    position: relative;
    z-index: 5;
    &__left {
        flex: 1;
        display: flex;
        align-items: center;
        .border-block {
            &:not(:last-child) {
                padding-right: 5px;
            }
        }
        .selected-info {
            color: #fff;
            font-weight: 300;
            text-transform: uppercase;
            span {
                display: inline-block;
                padding: 0 5px;
            }
        }
        .settings-block {
            position: absolute;
            z-index: 2;
            left: 5px;
            top: 45px;
            background: var(--white-light);
            border-radius: 4px;
            padding: 10px 0;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15%);
            width: 300px;
            animation: settingBlockShow 0.3s both;
            overflow: hidden;
            opacity: 0;
            // animation-delay: 0.1s;
            .options {
                width: 100%;
                padding: 0;
                margin: 0;
                list-style: none;
                .option {
                    display: block;
                    transition: all 0.3s;
                    // cursor: pointer;
                    font-size: 12pt;
                    font-weight: 600;
                    white-space: nowrap;
                    &__button {
                        white-space: nowrap;
                        padding: 10px 15px;
                        border-radius: 0;
                        &:hover {
                            background: var(--corp-green);
                            span {
                                color: var(--gray-90);
                            }
                            &:disabled {
                                background: none;
                                span{
                                    color: var(--gray-60);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__right {
        display: flex;
        align-items: center;
        .border-block {
            &:not(:first-child) {
                padding-left: 5px;
            }
        }
        .pages {
            color: #fff;
            padding: 2px 0;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 20px;
            cursor: default;
            span {
                font-weight: bold;
                padding-right: 2px;
            }
        }
    }
}

@keyframes settingBlockShow {
    0% {
        // width: 0;
        opacity: 0;
    }
    100% {
        // width: 300px;
        opacity: 1;
    }
}

.agp-heading {
  display: flex;
  align-items: center;
  &.flex-line{
    display: flex;
    align-items: center;
  }
}

.agp-heading_left {
  justify-content: flex-start;
}

.agp-heading_right {
  justify-content: flex-end;
}

.agp-h {
  font-family: Golos, sans-serif;
  margin: 0;
  color: var(--gray-90);
}

.agp-h1_size_medium {
  font-size: 32px;
  line-height: 40px;
}

.agp-h1_size_small {
  font-size: 26px;
  line-height: 32px;
}

.agp-h2_size_medium {
  font-size: 26px;
  line-height: 32px;
}

.agp-h2_size_small {
  font-size: 24px;
  line-height: 30px;
}

.agp-h3_size_medium {
  font-size: 22px;
  line-height: 26px;
}

.agp-h3_size_small {
  font-size: 21px;
  line-height: 24px;
}

.agp-h4_size_medium {
  font-size: 18px;
  line-height: 22px;
}

.agp-h4_size_small {
  font-size: 18px;
  line-height: 22px;
}

.agp-h5_size_medium {
  font-size: 16px;
  line-height: 20px;
}

.agp-h5_size_small {
  font-size: 16px;
  line-height: 20px;
}

.agp-h6_size_medium {
  font-size: 15px;
  line-height: 18px;
}

.agp-h6_size_small {
  font-size: 15px;
  line-height: 18px;
}

.agp-h1 {
  font-weight: 700;
}

.agp-h2, .agp-h3, .agp-h4, .agp-h5, .agp-h6 {
  font-weight: 600;
  word-break: break-word;
}


@media(max-width: 576px) {
  .agp-h1_size_medium{
    font-size: 26px;
    line-height: 32px;
  }
}
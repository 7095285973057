.notification-push {
    position: fixed;
    right: -100%;
    top: 70px;
    border-radius: 4px;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 3px solid var(--corp-green);
    z-index: 99999999999;
    max-width: 320px;
    transition: all 0.3s;
    &.open {
        animation: shakeJump 1s both;
        animation-delay: .1s;
        // right: 10px;
    }
    &__close {
        padding-left: 10px;
    }
    &__closeButton {
        cursor: pointer;
    }
    &__header {
        font-family: Golos, sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: var(--gray-90);
        margin-bottom: 16px;
    }
}

@keyframes shakeJump {
    0% {
        right: -100%;
    }
    55% {
        right: 25px;
    }
    70% {
        right: 0px;
    }
    80% {
        right: 17px;
    }
    90% {
        right: 7px;
    }
    100% {
        right: 10px;
    }
}

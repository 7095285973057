.agp-textfield {
  width: 100%;
}

.agp-textfield_small,
.agp-textfield_small .agp-textfield__input {
  width: 160px;
}

.agp-textfield_default,
.agp-textfield_default .agp-textfield__input {
  width: 240px;
}

.agp-textfield_halfWidth,
.agp-textfield_halfWidth .agp-textfield__input {
  width: 50%;
}

.agp-textfield_fullWidth .agp-textfield__input {
  width: 100%;
}
.agp-textfield__label {
  display: block;
  font-family: Golos, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.agp-textfield__label-margin-8 {
  margin-bottom: 8px;
}
.agp-textfield__label-margin-4 {
  margin-bottom: 4px;
}

.agp-textfield__input {
  font-family: Golos, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-90);
  resize: none;
  border: 1px solid var(--gray-30);
  border-radius: 4px;
  transition: border-color 0.1s ease-in-out;
  box-sizing: border-box;
  height: 48px;
  padding: 12px 15px;
  min-width: 160px;
}

.agp-textfield__input:hover {
  border-color: var(--gray-40);
}

.agp-textfield_error .agp-textfield__input,
.agp-textfield_error .agp-textfield__input:hover {
  border-color: var(--error-60);
}

.agp-textfield__input:focus,
.agp-textfield_error .agp-textfield__input:focus {
  //outline: none;
  //outline: 4px solid var(--focus);
  box-shadow: 0px 0px 0px 4px var(--focus);
  border: 1px solid var(--green);
}

.agp-textfield__input:disabled,
.agp-textfield__input:disabled:hover {
  background-color: var(--gray-10);
  color: var(--gray-60);
  border-color: var(--gray-20);
  cursor: not-allowed;
}

// фикс для автокомлита хрома
.agp-textfield__input:disabled:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--gray-10) inset;
}

.agp-textfield__info {
  color: var(--gray-80);
  margin: 4px 0 8px 0;
  font-size: 14px;
  a {
    font-size: 14px;
  }
}

.agp-textfield__input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.agp-textfield__endIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.agp-textfield__input--heightAuto {
  height: auto;
}

// МОБИЛА

@media (max-width: 768px) {
  .agp-textfield {
    max-width: 100%;
    width: 100%;
  }
}

.agp-textfield__isValid {
  margin-left: 6px;
}

$black20: #282c34;
.agp-checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.agp-checkbox__input:focus ~ .agp-checkbox__visual {
  // box-shadow: 0px 0px 0px 4px var(--focus);
}

.agp-checkbox {
}
.agp-checkbox_xs {
  margin-bottom: 8px;
}
.agp-checkbox_md {
  margin-bottom: 12px;
}
.agp-checkbox_lg {
  margin-bottom: 16px;
}

.agp-checkbox:last-child {
  margin-bottom: 0;
}

.agp-checkbox__label {
  margin-left: 12px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  font-family: Golos, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-90);
  font-weight: 400; //Пока поставил 400, 500-слишком жирный
  margin-bottom: 0;
  flex-direction: column;
}

.agp-checkbox__info {
  font-size: 14px;
  color: var(--gray-80);
  line-height: 20px;
  margin-top: 4px;
  display: block;
  margin-left: 32px;
}

.agp-checkbox__visual {
  content: "";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-30);
  border-radius: 4px;
  background-repeat: no-repeat;
  background-color: var(--white);
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  position: relative;
  margin: 0;
}

.checkbox__visual_margin {
  margin-top: 2px;
}

.agp-checkbox_center {
  display: flex;
  align-items: center;
}

.agp-checkbox_start {
  display: flex;
  align-items: flex-start;
}

// ховер на неотмеченный
.agp-checkbox__input:hover + .agp-checkbox__visual {
  border: 1px solid darken($black20, 80%);
  transition: border 0.1s ease-in-out;
}

.agp-checkbox__input:active + .agp-checkbox__visual {
  border: 1px solid darken($black20, 80%);
}

.agp-checkbox__input:checked + .agp-checkbox__visual {
  border-color: $black20;
  background-color: $black20;
}

.agp-checkbox__input:checked:hover + .agp-checkbox__visual {
  border-color: darken($black20, 70%);
  background-color: darken($black20, 70%);
  transition: background-color 0.1s ease-in-out;
}

.agp-checkbox__input:checked:active + .agp-checkbox__visual {
  border: 1px solid darken($black20, 80%);
}

.agp-checkbox__input_indeterminate + .agp-checkbox__visual {
  background-color: darken($black20, 60%);
}

.agp-checkbox__input_indeterminate:hover + .agp-checkbox__visual {
  background-color: darken($black20, 70%);
  transition: background-color 0.1s ease-in-out;
}

.agp-checkbox__input_indeterminate:active + .agp-checkbox__visual {
  background-color: darken($black20, 80%);
}

.agp-checkbox__input + .agp-checkbox__visual:focus {
  // border: 2.5px solid var(--focus);
  outline: none;
}

.agp-checkbox__visual_icon {
  color: var(--white);
}

/* стили для чекбокса, находящегося в состоянии disabled */
.agp-checkbox_disabled {
  .agp-checkbox__label {
    color: var(--gray-60);
    cursor: not-allowed;
  }

  .agp-checkbox__visual {
    cursor: not-allowed;
    pointer-events: auto;
    background-color: var(--gray-10);
    border: 1px solid var(--gray-20);
  }

  .agp-checkbox__input:checked + .agp-checkbox__visual {
    background-color: var(--gray-30);
    border: 1px solid var(--gray-30);
  }
  .agp-checkbox__input:hover + .agp-checkbox__visual {
    border: 1px solid var(--gray-30);
    transition: border 0.1s ease-in-out;
  }

  .agp-checkbox__input_indeterminate:hover + .agp-checkbox__visual {
    background-color: var(--gray-30);
    transition: border 0.1s ease-in-out;
  }
}

/* стили для чекбокса error */
.agp-checkbox_error {
  //enabled
  .agp-checkbox__input + .agp-checkbox__visual {
    border: 1px solid var(--error-60);
    &:hover {
      border-color: var(--error-70);
      transition: border 0.1s ease-in-out;
    }
  }
  //error checked
  .agp-checkbox__input:checked + .agp-checkbox__visual {
    background-color: var(--error-60);
    border: none;
    &:hover {
      background-color: var(--error-70);
      transition: background-color 0.1s ease-in-out;
    }

    &:active {
      background-color: var(--error-80);
    }

    &:focus {
      // box-shadow: 0 0 0 3px var(--focus);
    }
  }

  .agp-checkbox__input:checked:hover + .agp-checkbox__visual {
    background-color: var(--error-70);
    transition: background-color 0.1s ease-in-out;
  }
}

:root {    
    --corp-green-80: #007753;
    --corp-green-70: #00aa77;
    --corp-green-60: #00cc8b;
    --corp-green-30: #8fffda;
    --corp-green-20: #bbfff0;
    --corp-green-10: #ddfff4;
    --corp-green: #4ABEA3;
    --corp-green-25: #66cdaa;
    --green: #6cbba4;
    --black: #221f20;
    --black-20: #282c34;
    --black-dark: #000000;
    --white-light: #ffffff;

    --gray-90: #0e0e0f;
    --gray-80: #3c3e41;
    --gray-60: #696c71;
    --gray-40: #b2b5bb;
    --gray-30: #c4c8d0;
    --gray-20: #dfe1e6;
    --gray-10: #eeeff2;
    --gray-05: #f3f5f7;
    --blue-80: #006aba;
    --blue-70: #0091ff;
    --blue-60: #45afff;
    --blue-30: #8fb4ff;
    --blue-20: #bbcfff;
    --blue-10: #dde7ff;
    --blue-05: #ebf1ff;
    --brand: #cc2222;
    // --black: #000000;
    --white: #ffffff;
    --beige-05: #f7f5ee;
    --beige-80: #5b422c;
    --beige-60: #c5ad8d;
    --beige-25: #e1d0b9;
    --green-60: #b7cc1a;
    --green-25: #cfde64;
    --green-05: #f2fda5;
    --red-25: #ffabab;
    --red-05: #ffd8d8;
    --blue-25: #8ac0ff;
    --success-80: #003311;
    --success-70: #006633;
    --success-60: #008844;
    --success-30: #7cde8d;
    --success-20: #97edac;
    --success-10: #aff8c7;
    --success-05: #e6faf0;
    --warning-80: #cc6600;
    --warning-70: #ee8800;
    --warning-60: #ffaa00;
    --warning-30: #ffde77;
    --warning-20: #ffe8aa;
    --warning-10: #fff1cc;
    --error-80: #660000;
    --error-70: #880000;
    --error-60: #aa0000;
    --error-30: #ffa28f;
    --error-20: #ffc3bb;
    --error-10: #ffdedd;
    --error-05: #fff2f2;
    --focus: #6cbba4;
    --body: #ced0d2;

    --iconzky: #ed9821;
    --iconSchool: #65c261;
    --iconCityIdea: #4d9cda;
    --iconHealth: #2dab9c;
    --iconTransport: #7a44ca;
    --iconCharge: #4a78d4;
    --iconActiveCitizen: #007a6b;
    --iconSportSchool: #e95c30;

    --template-bg-grey: #f2f2f2;
    --template-bg-white: #ffffff;

    --xs: 575px;
    --sm: 576px;
    --md: 768px;
    --lg: 992px;
    --xl: 1200px;
    --xxl: 1400px;
}

#page404 {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    .page-block {
        width: 100%;
        max-width: 600px;
        background: var(--white-light);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        padding: 16px 24px;
        text-align: center;
        img{
            width: 25%;
        }
    }
}

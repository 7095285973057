.mob-menu {
    // position: absolute;
    width: 100vw;
    background: var(--black-20);
    padding: 5px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
        flex: 1;
        width: 100%;
        padding: 0;
        margin: 0;
        padding-right: 40px;
        display: flex;
        align-items: center;
        li {
            display: block;
            list-style: none;
            text-align: left;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
    button {
        .agp-button__content {
            color: var(--corp-green);
        }
    }
}
.mobmenu-btn{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
}


.agp-balloon__wrapper {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}
.agp-balloon__wrapper-primary {
  // background-color: var(--corp-green-10);
  border: 2px solid var(--corp-green);
}

.agp-balloon__wrapper-white {
  border: 1px solid var(--gray-30);
  background-color: var(--white);
}
.agp-balloon__wrapper-white.agp-balloon__wrapper-show {
  background-color: var(--corp-green-60);
}
.agp-balloon__wrapper-secondary {
  background-color: var(--corp-green-60);
}
.agp-balloon__content {
  position: absolute;
  padding: 12px 16px;
  max-width: 280px;
  width: max-content;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: GolosText, Golos, sans-serif;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 4px 12px rgba(0, 20, 67, 0.12);
  z-index: 9;
  cursor: default;
  text-align: left;
}
.agp-balloon__content-primary {
  background-color: var(--corp-green-10);
  border: 2px solid var(--corp-green-20);
}

.agp-balloon__content-secondary {
  background-color: var(--corp-green-05);
}
.agp-balloon__content-white {
  background-color: var(--corp-green-05);
}
